<template>
  <div>
    <p class="welcome__description">
      Para presentar propuestas al Premio FISE a la innovación, es necesario que
      se registre como usuario. Por favor verifique la correcta escritura de su
      correo electronico ya que a este le enviaremos todas las notificaciones.
    </p>
    <ValidationObserver ref="form" v-if="!isRegistered">
      <v-form @submit.prevent="signUp">
        <ValidationProvider
            name="Email"
            vid="username"
            v-slot="{ errors }"
            rules="required|email"
        >
          <v-text-field
              class="mb-3"
              v-model="model.username"
              :error-messages="errors"
              label="Email *"
              dense
              required
          ></v-text-field>
        </ValidationProvider>

        <ValidationProvider
            name="Contraseña"
            vid="password"
            v-slot="{ errors }"
            rules="required|min:8|max:25"
        >
          <v-text-field
              v-model="model.password"
              class="mb-3"
              dense
              :error-messages="errors"
              label="Contraseña *"
              type="password"
              required
          ></v-text-field>
        </ValidationProvider>

        <ValidationProvider
            name="Confirmar contraseña"
            vid="confirm"
            v-slot="{ errors }"
            rules="required|confirmed:password"
        >
          <v-text-field
              v-model="model.confirm"
              class="mb-3"
              dense
              :error-messages="errors"
              label="Confirmar contraseña *"
              type="password"
              required
          ></v-text-field>
        </ValidationProvider>

        <ValidationProvider
            name="Nombre"
            vid="name"
            v-slot="{ errors }"
            rules="required|max:50"
        >
          <v-text-field
              v-model="model.name"
              :counter="50"
              class="mb-3"
              dense
              :error-messages="errors"
              label="Nombre *"
              required
          ></v-text-field>
        </ValidationProvider>

        <ValidationProvider
            name="Apellido"
            vid="lastName"
            v-slot="{ errors }"
            rules="required|max:50"
        >
          <v-text-field
              v-model="model.lastName"
              :counter="50"
              class="mb-3"
              dense
              :error-messages="errors"
              label="Apellido *"
              required
          ></v-text-field>
        </ValidationProvider>

        <ValidationProvider
            name="Terminos"
            vid="acceptTerms"
            v-slot="{ errors }"
            :rules="{ required: { allowFalse: false } }"
        >
          <v-checkbox
              class="mt-0"
              v-model="model.acceptTerms"
              :error-messages="errors"
              :rules="[
              (v) =>
                v ||
                'Debes aceptar los términos y condiciones de uso de la información para continuar',
            ]"
          >
            <div slot="label">
              Acepta los
              <a
                  @click.stop
                  href="https://fise.co/wp-content/uploads/2020/06/habeas-data-terminos-de-informacion.pdf"
                  target="_blank"
              >
                Términos y condiciones de uso de la información.
              </a>
            </div>
          </v-checkbox>
          <v-text-field
            outlined
            v-model="model.captcha"
            label="captcha"
            v-show="false"
          />
        </ValidationProvider>
      </v-form>
      <div>
        <v-btn
            block
            class="elevation-0 text-none mb-3"
            color="primary"
            dark
            small
            @click="signUp"
        >Registrarse
        </v-btn>
      </div>
    </ValidationObserver>
    <v-alert v-if="isRegistered" color="green" text type="success">
      Realizaste con éxito el registro al Premio FISE a la Innovación.  Para activar tu usuario, es necesario verificar tu cuenta.  Te enviamos un correo con un enlace para la activación (revisa también en la carpeta de no deseados).
    </v-alert>
    <v-btn
      href="https://fise.co/conoce-fise-start/premioinnovacion/"
      v-if="isRegistered"
      block
      small
      class="elevation-0 text--primary text-none"
      color="secondary"
      dark
    >Aceptar
    </v-btn>

    <v-btn
       v-if="!isRegistered"
        block
        small
        class="elevation-0 text--primary text-none"
        color="secondary"
        dark
        to="/"
    >Iniciar sesión
    </v-btn>
  </div>
</template>

<script>
import {REGISTER_URL} from "@/constants/ServicesConstants";
import loaderMixin from "@/mixins/loaderMixin";
import notificationMixin from '@/mixins/notificationMixin';

export default {
  name: "Register",
  components: {},
  mixins: [loaderMixin, notificationMixin],
  data: () => ({
    registerUrl: REGISTER_URL,
    model: {
      acceptTerms: false,
      captcha: null,
      confirm: "",
      username: "",
      lastName: "",
      name: "",
      password: ""
    },
    isRegistered: false,
  }),
  methods: {
    async signUp() {
      const result = await this.$refs.form.validate();
      if (result) {
        this.showLoader();
        this.$axios
            .post(this.registerUrl, this.model)
            .then(() => {
              this.isRegistered = true;
              this.hideLoader();
            })
            .catch((error) => {
              this.hideLoader();
              if (error && error.response && error.response.status == 422) {
                this.invalidFields();
                this.$refs.form.setErrors({...error.response.data});
              } else {
                this.showError();
              }
            });
      }
    },
  },
};
</script>
